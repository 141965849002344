<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReportingAnalyticManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.reporting_analytics') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="content__list--links">
									<ul class="content__links--ul">
										<li>
											<router-link :to="`/${$i18n.locale}/account/reporting-analytics/1/`">{{
												$t('reports.go.px-1')
											}}</router-link>
										</li>

										<li>
											<router-link :to="`/${$i18n.locale}/account/reporting-analytics/2`">{{
												$t('reports.go.px-2')
											}}</router-link>
										</li>

										<li>
											<router-link :to="`/${$i18n.locale}/account/reporting-analytics/3/`">{{
												$t('reports.go.px-3')
											}}</router-link>
										</li>

										<li>
											<router-link :to="`/${$i18n.locale}/account/reporting-analytics/5/`">{{
												$t('reports.go.px-5')
											}}</router-link>
										</li>
										<li>
											<router-link :to="`/${$i18n.locale}/account/reporting-analytics/6/`">{{
												$t('reports.go.px-6')
											}}</router-link>
										</li>
										<li>
											<router-link :to="`/${$i18n.locale}/account/reporting-analytics/7/`"
												>{{ $t('reports.go.px-7') }}
											</router-link>
										</li>
										<li>
											<router-link :to="`/${$i18n.locale}/account/reporting-analytics/8/`">{{
												$t('reports.go.px-8')
											}}</router-link>
										</li>
										<li>
											<router-link :to="`/${$i18n.locale}/account/reporting-analytics/9/`">{{
												$t('reports.go.px-9')
											}}</router-link>
										</li>
										<li>
											<router-link :to="`/${$i18n.locale}/account/reporting-analytics/10/`">{{
												$t('reports.go.px-10')
											}}</router-link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ReportingGoList',
}
</script>
